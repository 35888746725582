import { Badge, Heading, Pane } from 'evergreen-ui';
import React from 'react';

export default function Header() {
  return (
    <Pane display={"flex"} justifyContent={"center"} marginBottom={16} flexDirection={"column"}>

        <Badge marginBottom={16} color="yellow">The website is currently under development</Badge>

      <Pane display={"flex"} justifyContent={"center"}>
        <img src="./media/daniteq_logo.png" width="50" height="50"  />
      </Pane>
    </Pane>
  );
}
import React from "react";
import Header from "./Pages/Header";
import HomePage from "./Pages/HomePage";
import Footer from "./Pages/Footer";

export default function App() {
  return (
    <div className="wrapper">
        <Header />
        <HomePage />
        <Footer />
    </div>
  );
}

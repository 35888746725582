import {
  Alert,
  AutomaticUpdatesIcon,
  Button,
  Card,
  CodeIcon,
  CornerDialog,
  Heading,
  Icon,
  IpAddressIcon,
  Link,
  Pane,
  Paragraph,
  StyleIcon,
  Text,
  toaster,
} from "evergreen-ui";
import JotformEmbed from "react-jotform-embed";
import React, { useRef } from "react";

export default function HomePage() {
  const ref = useRef<null | HTMLDivElement>(null);
  const [isShown, setIsShown] = React.useState(false);

  const handleQuoteButtonClick = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <Pane>
      {/* Banner - start */}
      <Pane background="tint1" padding={24} marginBottom={16}>
        <Pane display={"flex"} flexWrap={"wrap"} margin={"auto"} maxWidth={1140}>
          <Pane flex={"1 1 300px"}>
            <Heading size={900}>We deliver the website<br/>you need</Heading>
            <Paragraph marginTop={24} marginBottom={24}>We design, develop, host, manage brochure and e-commerce websites. We especialize in various technologies and platforms enabling us to provide the best solution for a given budget.</Paragraph>
            <Button appearance="primary" intent="success" onClick={handleQuoteButtonClick}>
              Get Free Quote
            </Button>
          </Pane>
          <Pane flex={"1 1 300px"} display={"flex"}>
            <Pane display={"flex"} flexBasis={"100%"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
              <img src="./media/homePage_banner.gif" />
            </Pane>
          </Pane>
        </Pane>
      </Pane>
      {/* Banner - end */}
      {/* CTA - start */}
      <Pane padding={24} marginBottom={16}>
        <Pane
          display={"flex"}
          margin={"auto"}
          maxWidth={1140}
          marginBottom={16}
        >
          <Heading size={800}>We Care For You</Heading>
        </Pane>
        <Pane
          display={"grid"}
          gridTemplateColumns={"repeat(auto-fill,minmax(275px, 1fr))"}
          gap={10}
          margin={"auto"}
          maxWidth={1140}
          marginBottom={16}
        >
          <Pane>
            <Icon icon={StyleIcon} size={24} color={"3366FF"} display={"inline-flex"} backgroundColor={"#EBF0FF"} padding={16} borderRadius={50} marginBottom={20}/>
            <Heading size={700}>Design</Heading>
            <Paragraph>We will work with you to visualize your concepts upholding UI/UX standards. We will create a brand guideline to follow during development & maintenance</Paragraph>
          </Pane>
          <Pane>
            <Icon icon={CodeIcon} size={24} color={"3366FF"} display={"inline-flex"} backgroundColor={"#EBF0FF"} padding={16} borderRadius={50} marginBottom={20}/>
            <Heading size={700}>Develop</Heading>
            <Paragraph>We will choose the best technology stack and platform to develop your website. The website will be fully accessible realizing the design with peak performance guaranteed</Paragraph>
          </Pane>
          <Pane>
            <Icon icon={IpAddressIcon} size={24} color={"3366FF"} display={"inline-flex"} backgroundColor={"#EBF0FF"} padding={16} borderRadius={50} marginBottom={20}/>
            <Heading size={700}>Host</Heading>
            <Paragraph>We provide affordable and dependable hosting that includes enterprise level perks such as high-performance servers, global CDN, pre-configured firewalls</Paragraph>
          </Pane>
          <Pane>
            <Icon icon={AutomaticUpdatesIcon} size={24} color={"3366FF"} display={"inline-flex"} backgroundColor={"#EBF0FF"} padding={16} borderRadius={50} marginBottom={20}/>
            <Heading size={700}>Manage</Heading>
            <Paragraph>We maintain the website to a high standard ensuring maximum uptime and performance that will guarantee best user-experience</Paragraph>
          </Pane>
        </Pane>
      </Pane>
      {/* CTA - end */}
      {/* Cards - start */}
      <Pane padding={24} marginBottom={16}>
        <Pane
          display={"flex"}
          margin={"auto"}
          maxWidth={1140}
          marginBottom={16}
        >
          <Heading size={800}>Next Steps For You</Heading>
        </Pane>
        <Pane margin={"auto"} maxWidth={1140} marginBottom={16}>
          <Alert
            intent="none"
            title="Special Offer for Non-Profit Organizations"
            marginBottom={32}
          >
            <Link cursor={"pointer"} onClick={() => setIsShown(true)}>Learn More</Link>
          </Alert>
        </Pane>
        <Pane
          display={"flex"}
          flexWrap={"wrap"}
          gap={25} 
          justifyContent={"space-between"}
          margin={"auto"}
          maxWidth={1140}
          marginBottom={16}
        >
          <Card display={"flex"} flexWrap={"wrap"} background="tint1" flex={"1 1 375px"} padding={24}>
            <Pane flex={"1 1 250px"}>
              <Heading size={600}>Got Questions?</Heading>
              <Paragraph>We are available to answer any enquiries</Paragraph>
              <Paragraph>We have lots of pre-curated packages to select from. Let's start right away</Paragraph>
              <Link cursor={"pointer"}  onClick={() => toaster.notify('This feature is coming soon!')}>Learn More</Link>
            </Pane>
            <Pane flex={"1 1 250px"} display={"flex"} justifyContent={"center"}>
                <img src="./media/quote_anime.gif" />
            </Pane>
          </Card>
          <Card display={"flex"} flexWrap={"wrap"} background="tint1" flex={"1 1 375px"} padding={24}>
            <Pane flex={"1 1 250px"}>
                <Heading size={600}>Top Notch Services</Heading>
                <Paragraph>We work round the clock so you can sitback or go on a world tour!</Paragraph>
                <Paragraph>Get a free no-obligation quote tailor-made to your requirements</Paragraph>
                <Link cursor={"pointer"}  onClick={handleQuoteButtonClick}>Get Free Quote</Link>
            </Pane>
            <Pane flex={"1 1 250px"} display={"flex"} justifyContent={"center"}>
                <img src="./media/holiday_sitback.gif" />
            </Pane>
          </Card>
        </Pane>
      </Pane>
      {/* Cards - end */}
      {/* Contact / Quote / Consultation Form - start */}
      <Pane padding={24} marginBottom={16}>
        <Pane
          display={"flex"}
          margin={"auto"}
          maxWidth={1140}
          ref={ref}
        >
          <JotformEmbed src="https://form.jotform.com/242584555773266" />
        </Pane>
      </Pane>
      {/* Contact / Quote / Consultation Form - end */}
      {/* Corner dialog for ngo - start */}
      <CornerDialog
        title="Special Limited Time Offer!"
        isShown={isShown}
        onCloseComplete={() => setIsShown(false)}
        onConfirm={(close) => {toaster.notify('This feature is coming soon!');close()}}
      >
        Non-profit organizations only pay for hosting. All maintenance costs are waived! Additionally, if you are already with another service, we will transfer at no costs!
      </CornerDialog>
      {/* Corner dialog for ngo - start */}
    </Pane>
  );
}

import { Button, Heading, Pane, Paragraph } from 'evergreen-ui';
import React from 'react';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  const goToLinkedIn = () => {
    window.open("https://www.linkedin.com/company/daniteq/", '_blank', 'noopener,noreferrer');
  }

  return (
    <Pane display={"flex"} justifyContent={"space-between"} marginTop={16} paddingTop={16} paddingBottom={16} borderTop={"1px solid #edeff5"}>
      <Paragraph>© Copyright {currentYear} · Daniteq</Paragraph>
      <Pane>
        <Button appearance={"minimal"} onClick={goToLinkedIn}>LinkedIn</Button>
      </Pane>
    </Pane>
  );
}